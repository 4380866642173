import React from 'react';
import Head from '../components/Head';
import { langOptions } from '../typings/core';

const Home = ({ lang, setLang }: langOptions) => {
    return (
        <div>
            <Head lang={lang} setLang={setLang} />
            
        </div>
    );
};

export default Home;